import * as React from 'react';
import { Switch, Route as DefaultRoute, RouteProps, Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import CabinetLayout from 'containers/Layout';
import WebsiteLayout from 'containers/WebsiteLayout';
import AuthorizationLayout from 'containers/AuthorizationLayout';

import Home from 'containers/Home';
import Receipt from 'containers/Receipt';
import Applications from 'containers/Applications'

import WorkShift from 'containers/Sales/WorkShift'
import Order from 'containers/Sales/Order'
import Sales from 'containers/Sales'
import Reports from 'containers/Reports'
import BusinessStructure from 'containers/BusinessStructure'
import Users from 'containers/Users'
import Products from 'containers/Products'
import Channels from 'containers/Channels'

import PhoneNumber from 'containers/Authorization/Login/PhoneNumber';
import Verification from 'containers/Authorization/Login/Verification';

import RegistrationPhoneNumber from 'containers/Authorization/Registration/PhoneNumber';
import RegistrationPhoneVerification from 'containers/Authorization/Registration/PhoneVerification';

import IbanOplata from 'containers/IbanOplata';

import PrivacyPolicy from 'containers/PrivacyPolicy';
import TermsOfService from 'containers/TermsOfService';

import InstructionForRegistration from 'containers/Instruction';

import Subscription from 'containers/Subscription';
import WorkSiftReportDoc from 'containers/WorkSiftReportDoc';

import Settings from 'containers/Settings';

import Acquiring from 'containers/Acquiring';

import Blog from 'containers/Blog';
import BlogSingle from 'containers/Blog/BlogSingle';

import NotFound from 'components/NotFound';

import authService from 'services/auth';
import useAnalytics from 'hooks/useAnalytics';
import ReceiptReturn from 'containers/ReceiptReturn';
import IbanQr from 'containers/IbanQr';
import DeleteAccount from "containers/DeleteAccount";

import Sip from 'containers/Sip';
import { UserRoleEnum } from 'api';

type CustomRouteProps = RouteProps & { layout?: RouteProps['component'], isPrivate?: boolean, restricted?: boolean, allowedRoles?: UserRoleEnum[] };

const Route: React.FC<CustomRouteProps> = (props) => {
  useAnalytics();

  const {
    component,
    layout,
    isPrivate,
    restricted,
    allowedRoles,
    ...rest
  } = props;
  const token = authService().getAccessToken();
  const userRole = useSelector(state => state.user.data.userRole);

  if (isPrivate && !token) {
    return <Redirect to={{ pathname: "/login", state: { from: rest.location?.pathname }}} />;
  }

  if (token && userRole && allowedRoles && !allowedRoles.includes(userRole)) {
    return <Redirect to="/" />;
  }

  if (restricted && token) {
    return <Redirect to="/" />;
  }

  if (layout && component) {
    return (
      <DefaultRoute
        {...rest}
        render={props => React.createElement(layout, props, React.createElement(component, props))}
      />
    )
  }

  return <DefaultRoute {...props} />
};

const Router: React.FC = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/receipt/:id' component={Receipt} />
    <Route exact path='/receipt/cr/:cruid' component={Receipt} />
    <Route exact path='/receipt-return/:uid' component={ReceiptReturn} />
    <Route exact path='/iban-qr/:uid' component={IbanQr} />
    <Route path='/app' component={Applications} />
  
    <Route exact path='/iban-oplata' component={IbanOplata} />

    <Route path='/public-offer' component={PrivacyPolicy} layout={WebsiteLayout} />
    <Route path='/terms-of-service' component={TermsOfService} layout={WebsiteLayout} />
    <Route path='/instruction-for-registration-prro' component={InstructionForRegistration} layout={WebsiteLayout} />
    <Route path='/blog/article/:id' component={BlogSingle} layout={WebsiteLayout} />
    <Route path='/blog' component={Blog} layout={WebsiteLayout} />
    <Route path='/sip/obraty-prro' component={Sip} />
    <Route isPrivate exact path='/work-shift-report/:uid' component={WorkSiftReportDoc} />
  
    <Route restricted path='/login' component={PhoneNumber} layout={AuthorizationLayout} />
    <Route restricted path='/verification' component={Verification} layout={AuthorizationLayout} />
    <Route restricted path='/registration/phone-number' component={RegistrationPhoneNumber} layout={AuthorizationLayout} />
    <Route restricted path='/registration/phone-verification' component={RegistrationPhoneVerification} layout={AuthorizationLayout} />

    <Route isPrivate path='/sales' component={Sales} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate exact path='/work-shift/:id' component={WorkShift} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate exact path='/work-shift/:id/order' component={Order} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/reports' component={Reports} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner, UserRoleEnum.Accountant]} />
    <Route isPrivate path='/business-structure' component={BusinessStructure} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/users' component={Users} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/products' component={Products} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/channels' component={Channels} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/settings' component={Settings} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/acquiring' component={Acquiring} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route isPrivate path='/subscription' component={Subscription} layout={CabinetLayout} allowedRoles={[UserRoleEnum.Owner]} />
    <Route exact path="/delete-account" component={DeleteAccount} layout={CabinetLayout} />

    <Route component={NotFound} />
  </Switch>
);

export default Router;
