import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ReceiptApi } from "api";
import { ReceiptResponse } from "api/models";
import Loader from "components/Loader";
import "./index.scss";
import ReceiptComponent from "./ReceiptComponent";

interface Params {
  id: string;
  cruid: string;
}

const receiptApi = new ReceiptApi();

const Receipt: React.FC = () => {
  const { id, cruid } = useParams<Params>();
  const [receipt, setReceipt] = useState<ReceiptResponse | null>(null);
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setIsReceiptLoading(true);
        const { data } = id
          ? await receiptApi.receiptGet(id)
          : await receiptApi.receiptCrGet(cruid);
        setReceipt(data);
      } finally {
        setIsReceiptLoading(false);
      }
    })();
  }, [id, cruid]);

  if (isReceiptLoading) {
    return <Loader isLoadingPage />;
  }

  return <ReceiptComponent receipt={receipt} />;

  return null;
};

export default Receipt;
