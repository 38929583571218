import * as React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import IbanOplataBg from "assets/images/iban-oplata-bg.jpg";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { Routes } from "shared/constants/routes";
import { StorePageKeys } from "shared/constants/storePage";

import { blue600, grey000, mainStyles } from "../styles";

const Main: React.FC = () => {
  const onAppButtonClick = () => {
    localStorage.setItem(StorePageKeys.STORE_PAGE, "iban");
    analytics().sendEvent(AnalyticsEvents.iban_oplata_download_app);
  };

  return (
    <>
      <Box sx={styles.mainBlock}>
        <Container>
          <Typography sx={mainStyles.ui1}>
            дозвольте платити вам швидше
          </Typography>
          <Typography
            sx={[mainStyles.h1, { whiteSpace: "pre-line" }]}
            mt={2}
            mb={3}
          >
            <Typography sx={[mainStyles.h1, styles.highlightedText]}>
              IBAN oplata
            </Typography>{" "}
            безкоштовний {"\n"}сервіс для підприємців
          </Typography>
          <Typography sx={mainStyles.h5}>
            Більше жодних довгих повідомлень з реквізитами.
          </Typography>
          <Box p={4} sx={styles.btnWrapper}>
            <Button
              sx={styles.installBtn}
              onClick={onAppButtonClick}
              href={Routes.app}
              target="_blank"
              size="large"
              variant="contained"
            >
              <Typography fontSize={14}>Завантажити додаток</Typography>
            </Button>
          </Box>
          <Link href="iban-oplata#how-it-works" sx={mainStyles.body2}>
            Дізнатись більшe ↓
          </Link>
        </Container>
      </Box>
    </>
  );
};

const styles = {
  mainBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    backgroundImage: `url(${IbanOplataBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: { xs: "80vh", md: "70vh" },
    paddingTop: { xs: "56px", sm: "60px" },
    paddingBottom: { xs: "56px", sm: "60px" },
  },
  btnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  highlightedText: {
    display: "inline",
    color: blue600,
  },
  installBtn: {
    backgroundColor: blue600,
    color: grey000,
    padding: "16px 24px",
    borderRadius: "1000px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "2px",
  },
};

export default Main;
