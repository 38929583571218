export enum StorePageKeys {
  STORE_PAGE = "STORE_PAGE",
}

export const storePages = {
  iban: {
    ios: "fd4e3a43-6de5-42a0-a523-5c9f350b013c",
    android: "ibanoplata",
  },
  prro: {
    ios: "43062420-7849-402b-b03d-b2a9736d864b",
    android: "homepage",
  },
  obratyPrro: {
    ios: "5100c4f5-e3c6-4a2a-903d-619ab250a50a",
    android: "obraty-prro",
  },
};
