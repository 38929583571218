import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EnhancedTableHead from './EnhancedTableHead'
import { Paper } from './styles'
import { Order, HeadCell, Row } from './types'
import { getComparator } from './utils'
import theme from 'theme';

interface IProps {
  idName?: string,
  rows: Row[],
  columns: HeadCell[],
  footer?: () => React.ReactElement,
  enablePagination?: boolean,
  checkboxSelection?: boolean;
  selected?: Row[];
  onSelectedChange?: (selected: Row[]) => void,
}

const ReportTable: React.FC<IProps> = ({ idName, rows, columns, footer, enablePagination = true, checkboxSelection = false, selected, onSelectedChange }) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setPage(0);
  }, [rows.length]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    window.scrollTo(0, 0);

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && selected && selected.length === 0) {
      onSelectedChange && onSelectedChange(rows);
      return;
    }
    onSelectedChange && onSelectedChange([]);
  };

  const handleClick = (row: Row) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selected) {
      let newSelected: Row[] = [];

      if(event.target.checked) {
        newSelected = newSelected.concat(selected, row);
      } else {
        newSelected = selected.filter(item => item.productId !== row.productId)
      }
      
      onSelectedChange && onSelectedChange(newSelected);
    }

  };

  const isSelected = (row: Row) => selected && selected.indexOf(row) !== -1;

  const renderRows = () => {
    let rowsToMap = rows.sort(getComparator(order, orderBy));

    if (enablePagination) {
      rowsToMap = rowsToMap.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return rowsToMap
      .map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const isItemSelected = isSelected(row);

        return <TableRow
          sx={{ bgcolor: isItemSelected ? theme.colors.backgroundLight : 'common.white' }}
          key={row[idName || 'id']}
          hover
        >                    
          {checkboxSelection && 
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onChange={handleClick(row)}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
          }
          {columns.map(({ id, align, width, cell = (v) => v}) => (
            <TableCell sx={!enablePagination && index == rows.length - 1 ? { borderBottom: 'none' } : {}} align={align} key={id} width={width}>{cell(row[id], row)}</TableCell>
          ))}
        </TableRow>
  })
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              headCells={columns}
              onRequestSort={handleRequestSort}
              rows={rows}
              selected={selected}
              checkboxSelection={checkboxSelection}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {renderRows()}
              {footer && footer()}
            </TableBody>
          </Table>
        </TableContainer>
        {enablePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}            
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

export default ReportTable;
