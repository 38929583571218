import React from "react";
import { connect } from "react-redux";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReportTable, HeadCell, Row } from "components/Table";

import { ReactComponent as IconCart } from "assets/images/icon_cart.svg";

import { ProductResponse } from "api";
import { actionCreators } from "store/Order";
import { getPriceFormat } from "shared/functions";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

interface Props {
  products: ProductResponse[];
}

interface ReduxProps {
  addProductToOrder: (data: ProductResponse) => void;
  setTotalAmount: () => void;
}

const Table: React.FC<Props & ReduxProps> = (props) => {
  const { products } = props;

  const onAddProduct = (row: Row) => {
    const { addProductToOrder, setTotalAmount } = props;
    addProductToOrder(row as ProductResponse);
    setTotalAmount();
    analytics().sendEvent(AnalyticsEvents.sales_add_product_to_order);
  };

  const columns: HeadCell[] = [
    {
      id: "name",
      label: "Назва",
      width: "40%",
      cell: (cell, row) => (
        <Typography variant="inherit" sx={{ overflowWrap: "anywhere" }}>
          {row.name}
        </Typography>
      ),
    },
    {
      id: "code",
      label: "Код",
      align: "center",
      width: "10%",
    },
    {
      id: "category",
      label: "Категорія",
      width: "20%",
      cell: (cell, row) => (
        <Typography variant="inherit">{row.category.name}</Typography>
      ),
    },
    {
      id: "price",
      label: "Ціна, ₴",
      width: "15%",
      align: "right",
      cell: (cell, row) => (
        <Typography variant="inherit" sx={{ whiteSpace: "nowrap" }}>
          {getPriceFormat(row.price)}
        </Typography>
      ),
      style: {
        whiteSpace: "nowrap",
      },
    },
    {
      id: "sell",
      label: "",
      align: "center",
      width: "15%",
      cell: (cell, row) => {
        return (
          <Link
            underline="none"
            component="button"
            display="flex"
            alignItems="center"
            onClick={() => onAddProduct(row)}
          >
            <IconCart />
            <Typography pl={1}>Продаж</Typography>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <ReportTable idName="order" rows={products} columns={columns} />
    </>
  );
};

const mapDispatchToProps = {
  addProductToOrder: actionCreators.addProductToOrder,
  setTotalAmount: actionCreators.setTotalAmount,
};

export default connect(() => ({}), mapDispatchToProps)(Table);
