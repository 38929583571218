import * as React from "react";
import { StorePageKeys } from "shared/constants/storePage";

import "./index.scss";

const UsageAdvices: React.FC = () => {
  const onAppButtonClick = () => {
    localStorage.setItem(StorePageKeys.STORE_PAGE, "obratyPrro");
  };

  return (
    <div className="usage-advices" id="usage-advices">
      <div className="usage-advices__text-wrapper">
        <h2 className="header-normal usage-advices__header">
          Як почати використовувати ПРРО на прикладі ФОПкаса?
        </h2>
        <ol className="usage-advices__list">
          <li key="k1" className="text-normal usage-advices__item">
            <a href="registration/phone-number" target="new">
              {" "}
              Зареєструйтесь
            </a>{" "}
            на сайті ФОПкаса. Потрібно лише номер телефону.
          </li>
          <li key="k2" className="text-normal usage-advices__item">
            <a href="/app" target="new" onClick={onAppButtonClick}>
              Завантажте
            </a>{" "}
            додаток ФОПкаса на ваш Android чи iOS мобільний пристрій.
          </li>
          <li key="k3" className="text-normal usage-advices__item">
            Зайдіть в додаток за номером телефону, що реєстрували. Тепер
            працюють всі функції в тестовому режимі.
          </li>
          <li key="k4" className="text-normal usage-advices__item">
            Спробуйте видати тестовий чек та будь які інші функції, які вам
            цікаві.
          </li>
          <li key="k5" className="text-normal usage-advices__item">
            <a href="/business-structure" target="new">
              Налаштуйте
            </a>{" "}
            фіскальний режим ПРРО в особистому кабінеті на сайті ФОПкаса.
          </li>
          <li key="k6" className="text-normal usage-advices__item">
            Продовжуйте видавати чеки в мобільному застосунку протягом двох
            місяців безкоштовно. Тепер вони фіскальні і повністю відповідають
            вимогам законодавства.
          </li>
          <li key="k7" className="text-normal usage-advices__item">
            Через два місяці оберіть тариф який вам підходить та продовжуйте
            користуватись.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default UsageAdvices;
