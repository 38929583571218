import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const WorkInProgressModal: React.FC<IProps> = ({ open, onClose }) => {
  const onAppButtonClick = () => {
    analytics().sendEvent(AnalyticsEvents.sales_modal_app_btn);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Продажі у розробці
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={300} paragraph>
          Робота з касою у веб версії ще у розробці, але у нас є мобільний
          додаток, через який ви можете здійснювати усі операції з касою.
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            onClick={onAppButtonClick}
            href="/app"
            target="_blank"
            size="large"
            variant="contained"
          >
            <Typography fontSize={14}>Завантажити додаток</Typography>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WorkInProgressModal;
