import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { QRCodeSVG } from 'qrcode.react';
import dayjs from 'dayjs';
import { ReceiptReturnApi } from 'api';
import { ReceiptReturnResponse } from 'api/models';
import { getPriceFormat } from 'shared/functions';
import Loader from 'components/Loader';
import Logo from 'assets/images/logo-black.png';
import './index.scss';

interface Params {
    uid: string
}

const receiptReturnApi = new ReceiptReturnApi();

const ReceiptReturn: React.FC = () => {
  const { uid } = useParams<Params>();
  const [receipt, setReceipt] = useState<ReceiptReturnResponse | null>(null);
  const [isReceiptLoading, setIsReturnReceiptLoading] = useState<boolean>(false);


  useEffect(() => {
    (async () => {
      try {
        setIsReturnReceiptLoading(true);
        const { data } = await receiptReturnApi.apiReceiptReturnUidGet(uid);
        setReceipt(data);
      } finally {
        setIsReturnReceiptLoading(false);
      }
    })();
  }, [uid]);

  if (isReceiptLoading) {
    return <Loader isLoadingPage />;
  }

  if (receipt) {
    const {
      companyName,
      companyAddress,
      localNumber,
      receiptProducts = 0,
      totalAmount = 0,
      receiptTimeUtc,
      pointOfSaleName,      
      receiptUrl,
      isFiscalReceipt,
      companyTin,
      cashierName,
      fiscalNumber,
      cashRegisterFiscalNumber,
      reportName,
      isOffline,
      receiptFiscalNumber,
      receiptCardPayment
    } = receipt;

    const {
      acquirerIdentifier,
      terminalIdentifier,
      maskedPan,
      paymentSystem,
      approvalCode,
      rrn,
    } = receiptCardPayment || {};

    const receiptItem = (title: string, data: string | null | number = '') => {
      return (
        <div className="receipt__text-wrapper">
          <div>
            <span className="receipt__text">{title}</span>
          </div>
          <div>
            <span className="receipt__text">{data}</span>
          </div>
        </div>
      )
    }
    return (
      <div>
        <section className="receipt">
          <div className="receipt__wrapper">
            <p className="receipt__text">
              {pointOfSaleName}<br/>
              {companyName}
            </p>
            <p className="receipt__text">
              {companyAddress}
            </p>
            {isFiscalReceipt && (
              <p className="receipt__text">
                ІД: {companyTin} &nbsp;&nbsp; ФН: {cashRegisterFiscalNumber}<br/>
                Касир: {cashierName}
              </p>
            )}
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <div>
                <p className="receipt__text">Поверення для документа № {receiptFiscalNumber}</p>
              </div>
            </div>
          </div>

          <div className="receipt__wrapper">
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            {receiptProducts && receiptProducts.map(({ name, price = 0, quantity = 0, productProperties, unit }) => (
              <div key={name} className="receipt__text-wrapper">
                <p className="receipt__text receipt__item">
                  {name}<br/>
                  {!!productProperties && productProperties}
                  {!!productProperties && <br/>}
                  {getPriceFormat(price)} x {unit.isDiscrete ? Number(quantity).toFixed(2) : Math.round(quantity)} {unit.symbol}. =
                </p>
                <p className="receipt__text">
                                <span className="receipt__price">
                                    {getPriceFormat(price * quantity)}
                                </span>
                </p>
              </div>
            ))}
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <p className="receipt-text receipt__text">
                СУМА:
              </p>
              <p className="receipt-text receipt__text">
                {getPriceFormat(totalAmount)}
              </p>
            </div>
          </div>
          { receiptCardPayment &&
            <div className="receipt__wrapper">
              {receiptItem('ІДЕНТ. ЕКВАЙРА  ', acquirerIdentifier)}
              {receiptItem('ТЕРМІНАЛ', terminalIdentifier)}
              {receiptItem('ВИД ОПЕРАЦІЇ   ', 'Оплата')}
              {receiptItem('ЕПЗ', maskedPan)}
              {receiptItem('ПЛАТІЖНА СИСТЕМА ', paymentSystem)}
              {receiptItem('КОД АВТ.', approvalCode)}
              {receiptItem('RRN', rrn)}
            </div>
          }
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <div>
                <p className="receipt__text">Без ПДВ</p>
              </div>
            </div>
          </div>
          <div className="receipt__wrapper">
            <div className="receipt__text-wrapper">
              <div>
                <p className="receipt__text">Номер чеку: {localNumber}</p>
                <p className="receipt__text">Дата: {dayjs.utc(receiptTimeUtc).local().format('DD.MM.YYYY, HH:mm:ss')}</p>
                {isFiscalReceipt && (
                  <>
                    <p className="receipt__text">Фіскальний номер чеку: {fiscalNumber}</p>
                    <p className="receipt__text">Режим роботи: {isOffline ? 'офлайн' : 'онлайн'}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <p className="receipt__text">
            {reportName}
          </p>
          <div className="receipt__qrcode-wrapper">
            <QRCodeSVG
              value={receiptUrl || ''}
              size={220}
              level="Q"
              imageSettings={{
                src: Logo,
                width: 80,
                height: 80,
                excavate: true
              }} />
          </div>
        </section>
      </div>
    );
  }

  return null;
};

export default ReceiptReturn;
