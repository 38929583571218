import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CancelSubscription from './CancelSubscription';
import { Start, Successful, SuccessfulYear, Free } from '../Home/components/Prices/Tariffs';

import analyticsService from 'services/analytics';
import { actionCreators } from 'store/Subscription';
import { actionCreators as  actionNotification } from 'store/Notification';
import { SubscriptionContext } from 'contexts/SubscriptionContext';
import { AnalyticsEvents } from 'shared/constants/analytics';

export enum SubscriptionPaymentPlan {
  Free = 1,
  Start = 2,
  Success = 3,
  SuccessfulYear = 4
}

const Subscription: React.FC = () => {
  const searchParams = new URLSearchParams(location.search);
  const paymentStatus = searchParams.get('payment_status');

  const paymentPlanId = useSelector(state => state.subscription.subscription.paymentPlanId);
  const isFreePlanSelected = paymentPlanId === SubscriptionPaymentPlan.Free;

  const dispatch = useDispatch();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const { getSubscription, createSubscriptionPayment } = actionCreators;
  const { setNotification } = actionNotification;

  useEffect(() => {
    dispatch(getSubscription())
    if (paymentStatus === 'success') {
      dispatch(setNotification({
        message: 'Оплата пройшла успішно.  Ваш Тариф використовує автопродовження підписки до моменту її скасування. Приємного користування додатком ФОПкаса',
        severity: 'success'
      }));
      analyticsService().sendEvent(AnalyticsEvents.subscription_payment_success);
    }
    if (paymentStatus === 'failed') {
      dispatch(setNotification({
        message: 'Трапилась помилка при здійснені оплати. Зверніться в Службу підтримки для вирішення проблеми.',
        severity: 'error'
      }));
      analyticsService().sendEvent(AnalyticsEvents.subscription_payment_fail);
    }
  }, []);

  const createPayment = useCallback((paymentPlanId: SubscriptionPaymentPlan) => () => {
    dispatch(createSubscriptionPayment({ paymentPlanId }, url => location.replace(url)));
  }, []);

  const onCloseModal = () => setCancelModalOpen(false);
  const onOpenModal = () => setCancelModalOpen(true);

  return (
    <Box>
      <Typography
        variant='h4'
        component='h1'
        fontWeight={500}
        margin='24px 0 16px 0'
      >
        Мій тариф
      </Typography>
      <SubscriptionContext.Provider value={{ onOpenModal }}>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          sx={{ overflowX: isFreePlanSelected ? 'scroll' : 'hidden', maxHeight: "100%" }}
        >
          <Start isLanding={false} createPayment={createPayment(SubscriptionPaymentPlan.Start)} />
          <Successful isLanding={false} createPayment={createPayment(SubscriptionPaymentPlan.Success)}/>
          <SuccessfulYear isLanding={false} createPayment={createPayment(SubscriptionPaymentPlan.SuccessfulYear)}/>
          { isFreePlanSelected && <Free /> }          
        </Box>
        <CancelSubscription
          isOpenModal={cancelModalOpen}
          onCloseModal={onCloseModal}
        />
      </SubscriptionContext.Provider>
    </Box>
  )
};

export default Subscription;
