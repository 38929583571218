import React, { FC, useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import PaymentDialogContent from "./PaymentDialogContent";
import Loader from "components/Loader";

import { ReactComponent as CloseIcon } from "assets/images/icon_delete_input.svg";

import { PaymentTypeEnum } from "api";
import { DiscountTypeEnum } from "store/Order";
import { ApplicationState } from "store";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { getPriceFormat } from "shared/functions";

interface ReduxProps {
  totalAmount: number;
  discountType: DiscountTypeEnum;
  discountValue: number;
  paymentType: PaymentTypeEnum;
  providedCashAmount: number;
}

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onCreateReceipt: (onComplete: () => void) => void;
}

const PaymentDialog: FC<Props & ReduxProps> = (props) => {
  const [isLoading, setLoading] = useState(false);
  const {
    totalAmount,
    discountType,
    discountValue,
    providedCashAmount,
    paymentType,
    isOpenModal,
    onCloseModal,
    onCreateReceipt,
  } = props;

  const discountAmount =
    discountType === DiscountTypeEnum.Amount
      ? discountValue
      : Number(((totalAmount / 100) * discountValue).toFixed(2));

  const getRoundedPrice = (
    totalAmount: number,
    discountAmount: number,
    isRound: boolean = true
  ): number => {
    let roundPrice: number = Number(totalAmount.toFixed(2));

    roundPrice = totalAmount - discountAmount;

    if (isRound) {
      roundPrice = Math.round(roundPrice * 10) / 10;
    }

    return roundPrice;
  };

  const amountToPay = getRoundedPrice(
    totalAmount,
    discountAmount,
    paymentType === PaymentTypeEnum.Cash
  );

  const onCreate = () => {
    setLoading(true);
    analytics().sendEvent(AnalyticsEvents.sales_create_receipt);
    onCreateReceipt(() => {
      setLoading(false);
    });
  };

  return (
    <Dialog
      fullWidth
      open={isOpenModal}
      PaperProps={{ sx: { minHeight: 420, maxWidth: 424 } }}
    >
      <DialogTitle>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={4}
          >
            <Typography variant="h3">Сума до сплати:</Typography>
            <Typography variant="h2" fontSize="24px">
              {getPriceFormat(amountToPay)} ₴
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
          >
            <Typography variant="body1">Загальна сума:</Typography>
            <Typography variant="body1">
              {getPriceFormat(totalAmount)} ₴
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={2}
          >
            <Typography variant="body1">Знижка:</Typography>
            <Typography variant="body1">
              {getPriceFormat(discountAmount)} ₴
            </Typography>
          </Box>
        </Box>

        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? <Loader /> : <PaymentDialogContent />}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ margin: "16px" }}
          fullWidth
          size="large"
          variant="contained"
          disabled={
            (paymentType === PaymentTypeEnum.Cash &&
              (isNaN(providedCashAmount) ||
                providedCashAmount < amountToPay)) ||
            isLoading
          }
          onClick={onCreate}
        >
          Продовжити
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ order }: ApplicationState) => ({
  totalAmount: order.totalAmount,
  discountValue: order.discount.value || 0,
  discountType: order.discount.type,
  paymentType: order.paymentType,
  providedCashAmount: order.providedCashAmount || 0,
});

export default connect(mapStateToProps, {})(PaymentDialog);
