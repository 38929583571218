export const Routes = {
  home: "/",

  // Auth
  login: "/login",
  verification: "/verification",
  registrationPhoneNumber: "/registration/phone-number",
  registrationPhoneVerification: "/registration/phone-verification",

  // Cabinet
  sales: "/sales",
  workShift: "/work-shift",
  order: "/order",
  reports: "/reports",
  businessStructure: "/business-structure",
  users: "/users",
  products: "/products",
  channels: "/channels",
  settings: "/settings",
  acquiring: "/acquiring",
  subscription: "/subscription",
  deleteAccount: "/delete-account",

  // Work Shift
  workShiftReport: "/work-shift-report",

  // Receipt
  receipt: "/receipt",
  receiptCr: "/receipt/cr",
  receiptReturn: "/receipt-return",

  // Website
  publicOffer: "/public-offer",
  termsOfService: "/terms-of-service",
  instructionForRegistrationPrro: "/instruction-for-registration-prro",
  sipObratyPrro: "/sip/obraty-prro",
  ibanOplata: "/iban-oplata",

  // Blog
  blog: "/blog",
  blogArticle: "/blog/article",

  // Other
  app: "/app",
};
