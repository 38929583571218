import { Action, Reducer } from "redux";
import { MonoQrInvoiceApi, ReceiptApi } from "../api";
import {
  MonoApiQrInvoiceStatusEnum,
  MonoQrInvoiceDto,
  PaymentTypeEnum,
  ReceiptResponse,
} from "../api/models";
import { AppThunkAction } from "./";
import { ApiError } from "types";
import {
  actionCreators as notificationActions,
  SetNotificationAction,
} from "./Notification";
import { DiscountTypeEnum, OrderState } from "./Order";

const receiptApi = new ReceiptApi();
const monoQrInvoiceApi = new MonoQrInvoiceApi();

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReceiptState {
  receipt: ReceiptResponse | null;
  monoQrInvoice:
    | (MonoQrInvoiceDto & {
        monoInvoiceIntervalId?: NodeJS.Timeout;
        receiptUid?: string;
      })
    | null;
}

enum ReceiptActionTypes {
  SET_RECEIPT = "SET_RECEIPT",
  SET_MONO_QR_INVOICE = "SET_MONO_QR_INVOICE",
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetReceiptAction {
  type: ReceiptActionTypes.SET_RECEIPT;
  payload: ReceiptResponse | null;
}

export interface SetMonoQrInvoiceAction {
  type: ReceiptActionTypes.SET_MONO_QR_INVOICE;
  payload:
    | (MonoQrInvoiceDto & {
        monoInvoiceIntervalId?: NodeJS.Timeout;
        receiptUid?: string;
      })
    | null;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | SetReceiptAction
  | SetMonoQrInvoiceAction
  | SetNotificationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  setReceipt: (payload: ReceiptResponse | null): SetReceiptAction => ({
    type: ReceiptActionTypes.SET_RECEIPT,
    payload,
  }),
  setMonoQrInvoice: (
    payload:
      | (MonoQrInvoiceDto & {
          monoInvoiceIntervalId?: NodeJS.Timeout;
          receiptUid?: string;
        })
      | null
  ): SetMonoQrInvoiceAction => ({
    type: ReceiptActionTypes.SET_MONO_QR_INVOICE,
    payload,
  }),
  getReceipt:
    (
      receiptUid: string,
      onSuccess?: (receipt?: ReceiptResponse) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await receiptApi.receiptGet(receiptUid);

        dispatch(actionCreators.setReceipt(data));

        if (onSuccess) {
          onSuccess(data);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  createReceipt:
    (
      workShiftId: number,
      paymentType: PaymentTypeEnum,
      order: OrderState,
      onSucess?: (receiptUid?: string) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const { data } = await receiptApi.receiptPost({
          workShiftId: workShiftId,
          providedCashAmount: order.providedCashAmount || 0,
          paymentType: paymentType,
          discountPercentage:
            order.discount.type === DiscountTypeEnum.Percentage
              ? order.discount.value!
              : 0,
          discountAmount:
            order.discount.type === DiscountTypeEnum.Amount
              ? order.discount.value!
              : 0,
          notes: order.notes,
          isPublicNotes: order.isPublicNotes,
          products: order.products.map((product) => {
            return {
              productId: product.productId!,
              price: product.price!,
              quantity: product.quantity!,
            };
          }),
        });

        if (onSucess) {
          onSucess(data.receiptUid);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;
        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  createMonoQrInvoice:
    (
      receiptUid: string,
      onSuccess?: (monoQrInvoice: MonoQrInvoiceDto) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const data = await monoQrInvoiceApi.apiMonoQrInvoicesPost({
          receiptUid,
        });

        dispatch(actionCreators.setMonoQrInvoice(data.data));

        if (onSuccess) {
          onSuccess(data.data);
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  getMonoQrInvoice:
    (
      invoiceId: string,
      monoInvoiceIntervalId?: NodeJS.Timeout | undefined,
      onSuccess?: (monoQrInvoice: MonoQrInvoiceDto) => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        const data = await monoQrInvoiceApi.apiMonoQrInvoicesInvoiceIdGet(
          invoiceId
        );

        if (monoInvoiceIntervalId) {
          if (data.data.status === MonoApiQrInvoiceStatusEnum.Success) {
            clearInterval(monoInvoiceIntervalId);
            dispatch(actionCreators.setMonoQrInvoice(null));
            if (onSuccess) {
              onSuccess(data.data);
            }
            return;
          }

          if (
            data.data.status &&
            [
              MonoApiQrInvoiceStatusEnum.Failure,
              MonoApiQrInvoiceStatusEnum.Expired,
              MonoApiQrInvoiceStatusEnum.Cancelled,
            ].includes(data.data.status)
          ) {
            clearInterval(monoInvoiceIntervalId);
            monoInvoiceIntervalId = undefined;
          }
        }

        dispatch(
          actionCreators.setMonoQrInvoice({
            ...data.data,
            monoInvoiceIntervalId,
          })
        );
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  cancelMonoQrInvoice:
    (
      invoiceId: string,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await monoQrInvoiceApi.apiMonoQrInvoicesInvoiceIdCancelPut(invoiceId);
        dispatch(actionCreators.setMonoQrInvoice(null));

        if (onSuccess) {
          onSuccess();
          dispatch(
            notificationActions.setNotification({
              message: "Оплату успішно скасовано",
              severity: "success",
            })
          );
        }
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
  sendReceipt:
    (
      receiptUid: string,
      email: string,
      customerName: string,
      onSuccess?: () => void,
      onError?: () => void
    ): AppThunkAction<KnownAction> =>
    async (dispatch) => {
      try {
        await receiptApi.receiptSendEmailPost({
          receiptUid,
          email,
          customerName,
        });

        if (onSuccess) {
          onSuccess();
        }

        dispatch(
          notificationActions.setNotification({
            message: "Чек надіслано",
            severity: "success",
          })
        );
      } catch ({ response }) {
        if (onError) {
          onError();
        }

        const { data } = response as ApiError;

        dispatch(
          notificationActions.setNotification({
            message: data.title,
            severity: "error",
          })
        );
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultState: ReceiptState = {
  receipt: null,
  monoQrInvoice: null,
};

export const reducer: Reducer<ReceiptState> = (
  state: ReceiptState = defaultState,
  incomingAction: Action
): ReceiptState => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ReceiptActionTypes.SET_RECEIPT:
      return { ...state, receipt: action.payload };
    case ReceiptActionTypes.SET_MONO_QR_INVOICE:
      return { ...state, monoQrInvoice: action.payload };
    default:
      return state;
  }
};
