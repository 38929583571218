import React, { useState } from "react";
import { isIOS } from "react-device-detect";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import OtherBank from "assets/images/other-bank.png";

import analytics from "services/analytics";
import { AnalyticsEvents } from "shared/constants/analytics";

import { Bank, BankKeys, banks } from "shared/constants/banks";
import { grey700, mainStyles } from "containers/IbanOplata/styles";

interface Props {
  preferedBankName: string;
  setPreferedBank: (bankName: string) => void;
  constructBankUrl: (bank: Bank) => string;
}

const Banks: React.FC<Props> = (props) => {
  const { preferedBankName, setPreferedBank, constructBankUrl } = props;
  const [showAll, setShowAll] = useState(false);

  const sliceValue = showAll ? 5 : 4;

  const preferedBank = banks.filter(
    (item) => item.name === preferedBankName
  )[0];

  const platformBanks = isIOS
    ? banks.filter((bank) => bank.packageIOS)
    : banks.filter((bank) => bank.packageAndroid);

  const filteredBanks = preferedBank
    ? Array.from(new Set([preferedBank, ...platformBanks]))
    : platformBanks;

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const onBankClick = (bank: Bank) => {
    const url = constructBankUrl(bank);
    window.location.assign(url);

    setPreferedBank(bank.name);
    localStorage.setItem(BankKeys.PREFERED_BANK, bank.name);
    analytics().sendEvent(`${AnalyticsEvents.iban_qr_pay_}${bank.shortName}`);
  };

  return (
    <Box maxWidth="340px" margin="0 auto">
      <Grid container spacing={2}>
        {filteredBanks.slice(0, sliceValue).map((bank, index) => (
          <Grid item xs={2.4} sm={2.4} key={index}>
            <ButtonBase
              sx={{ width: "100%" }}
              disableRipple
              disableTouchRipple
              onClick={() => onBankClick(bank)}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                maxWidth="100%"
              >
                {bank.logo}
                <Typography
                  sx={[
                    mainStyles.ui2,
                    {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "inherit",
                      textAlign: "center",
                    },
                  ]}
                  mt={1}
                >
                  {bank.name}
                </Typography>
              </Box>
            </ButtonBase>
          </Grid>
        ))}
        {!showAll && (
          <Grid item xs={2.4} sm={2.4}>
            <ButtonBase
              onClick={handleToggleShowAll}
              sx={{ width: "100%" }}
              disableRipple
              disableTouchRipple
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                maxWidth="100%"
              >
                <Box component="img" src={OtherBank} alt="Інші" />
                <Typography
                  sx={[
                    mainStyles.ui2,
                    {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "inherit",
                      textAlign: "center",
                    },
                  ]}
                  mt={1}
                >
                  Інші
                </Typography>
              </Box>
            </ButtonBase>
          </Grid>
        )}
      </Grid>

      <Collapse in={showAll} timeout="auto" unmountOnExit>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          {filteredBanks.slice(sliceValue).map((bank, index) => (
            <Grid item xs={2.4} sm={2.4} key={index + 4}>
              <ButtonBase
                sx={{ width: "100%" }}
                disableRipple
                disableTouchRipple
                onClick={() => onBankClick(bank)}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  maxWidth="100%"
                >
                  {bank.logo}
                  <Typography
                    sx={[
                      mainStyles.ui2,
                      {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "inherit",
                        textAlign: "center",
                      },
                    ]}
                    mt={1}
                  >
                    {bank.name}
                  </Typography>
                </Box>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
        <Box pt={1.5} textAlign="center">
          <Typography sx={[mainStyles.ui2, { color: grey700 }]}>
            Не знайшли вашого банку?
          </Typography>
          <Typography sx={mainStyles.ui2}>
            Ви завжди можете пренести данні в ручну.
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Banks;
