import React, { useEffect } from 'react';
import { History } from 'history';

import { Routes } from 'shared/constants/routes';
import { ApplicationsUrl } from '../../shared/constants/applications';

import Logo from '../../assets/images/logo-outlined.svg';
import DownloadAndroid  from '../../assets/images/download-android-app.svg';
import DownloadIOS  from '../../assets/images/download-ios-app.svg';

import './index.scss';
import { storePages, StorePageKeys } from 'shared/constants/storePage';

interface Props {
    history: History
}

const Applications: React.FC<Props> = props => {
    const { location } = window;

  useEffect(() => {
    const { userAgent } = navigator;

    const isIPhone = /iPhone/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);

    const storePage = (localStorage.getItem(StorePageKeys.STORE_PAGE) ||
      "prro") as keyof typeof storePages;

    if (isIPhone)
      location.href = ApplicationsUrl.ios + storePages[storePage].ios;
    if (isAndroid)
      location.href = ApplicationsUrl.android + storePages[storePage].android;

    return () => {
      localStorage.removeItem(StorePageKeys.STORE_PAGE);
    };
  }, []);

  const redirectToAppIOS = () => {
    const storePage = (localStorage.getItem(StorePageKeys.STORE_PAGE) ||
      "prro") as keyof typeof storePages;
    location.href = ApplicationsUrl.ios + storePages[storePage].ios;
  };

  const redirectToAppAndroid = () => {
    const storePage = (localStorage.getItem(StorePageKeys.STORE_PAGE) ||
      "prro") as keyof typeof storePages;
    location.href = ApplicationsUrl.android + storePages[storePage].android;
  };

    return (
        <div className='applications'>
            <div className='applications__content'>
                <img
                    onClick={() => props.history.push(Routes.home)}
                    className='applications__content-logo pointer'
                    alt="Logo"
                    src={Logo}
                />
                <span className='applications__content-title'>
                    Завантажуйте безкоштовний додаток
                </span>
                <div className='applications__content-download'>
                    <img
                        className='pointer'
                        alt="DownloadAndroid"
                        src={DownloadAndroid}
                        onClick={redirectToAppAndroid}
                    />
                    <img
                        className='applications__content-download-ios pointer'
                        alt="DownloadIOS"
                        src={DownloadIOS}
                        onClick={redirectToAppIOS}
                    />
                </div>
            </div>
        </div>
    );
};

export default Applications;
