import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import TariffFeatureItem from './TariffFeatureItem';
import CurrentTariff from "./CurrentTariff";

import {
  TariffButton,
  TariffContainer,
  TariffFeatureContainer,
  TariffOpportunitiesContainer,
  TariffTitleContainer
} from './styles';

import { ReactComponent as MarkIcon } from 'assets/images/icon_mark.svg';
import { SubscriptionPaymentPlan } from 'containers/Subscription/Subscription';
import { useGetSubscriptionPlanButtonText } from 'hooks';
import { Routes } from 'shared/constants/routes';

import analytics from 'services/analytics';
import { AnalyticsEvents } from 'shared/constants/analytics';


const tariffFeatures = [
  'Необмежена кількість торгових точок та кас.',
  'Поділ товарів на категорії та фільтри.',
  'Зручна база товарів.',
  'Можливість залишати коментарі до чеків.',
  'Можливість автоматичного надсилання замовлень через telegram.',
  'Видача чеків, коли немає з\'єднання з серверами податкової.',
  'Професійна служба підтримки.'
];

interface IProps {
  createPayment: () => void
  isLanding: boolean
}

const Start: FC<IProps> = props => {
  const paymentPlanId = useSelector(state => state.subscription.subscription.paymentPlanId);
  const isCancelled = useSelector(state => state.subscription.subscription.isCancelled);
  const authorized = useSelector(state => state.user.authorized);
  const history = useHistory();
  const buttonText = useGetSubscriptionPlanButtonText(props.isLanding, SubscriptionPaymentPlan.Start);
  const isCurrentPlanSelected = paymentPlanId === SubscriptionPaymentPlan.Start;

  return (
    <TariffContainer sx={{ minWidth: props.isLanding ? 'none' : '345px' }}>
      <TariffTitleContainer sx={({ colors }) => ({ background: colors.tariffStartGradient })}>
        <Typography fontSize={32}>
          Стартовий
        </Typography>
        <Typography display='inline' fontSize={32}> 115</Typography>
        <Typography display='inline' fontSize={18} fontWeight={600}> грн./місяць</Typography>
        { props.isLanding && <Typography display='inline' fontSize={12}>, безкоштовно перші 2 місяці</Typography> }              
      </TariffTitleContainer>
      <TariffFeatureContainer>
        <ListItemAvatar sx={{ minWidth: '15px' }}>
          <MarkIcon/>
        </ListItemAvatar>
        <Box ml={1}>
          <Typography display='inline' fontSize={18} fontWeight={600}>
            10 фіскальних чеків на день безкоштовно.
          </Typography>
          <Typography>
            Вартість 1 грн/чек починаючи з 11-го чека.
          </Typography>
        </Box>
      </TariffFeatureContainer>
      <TariffOpportunitiesContainer>
        {tariffFeatures.map((tariffFeature) => (
          <TariffFeatureItem
            tariffFeature={tariffFeature}
            key={tariffFeature}
          />
        ))}
        {isCurrentPlanSelected && !isCancelled ?
          <CurrentTariff/> :
          <TariffButton
            onClick={() => {
              if (props.isLanding && !authorized) history.push(`${Routes.registrationPhoneNumber}?tariff=start`);
              else props.createPayment();
              analytics().sendEvent(AnalyticsEvents.subscription_payment_plan_start);
            }}
            fullWidth
            size='large'
            variant='contained'
            color='secondary'
          >
            {buttonText}
          </TariffButton>
        }
      </TariffOpportunitiesContainer>
    </TariffContainer>
  );
};

export default Start;
