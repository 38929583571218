export enum Env {
  DEV = "DEV",
  STG = "STG",
  PROD = "PROD",
}

const getEnv = (): Env => {
  const host = window.location.hostname;

  if (
    host.includes("fopkasa-web-dev.azurewebsites.net") ||
    host.includes("localhost")
  ) {
    return Env.DEV;
  } else if (host.includes("fopkasa-web-stg.azurewebsites.net")) {
    return Env.STG;
  } else {
    return Env.PROD;
  }
};

export default getEnv;
