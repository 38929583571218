import React from "react";
import { UserRoleEnum } from "api";

import { ReactComponent as IconSales } from "assets/images/icon_sales.svg";
import { ReactComponent as IconReports } from "assets/images/icon_reports.svg";
import { ReactComponent as IconMyBussiness } from "assets/images/icon_my_bussiness.svg";
import { ReactComponent as IconCashier } from "assets/images/icon_cashier.svg";
import { ReactComponent as IconGoods } from "assets/images/icon_goods.svg";
import { ReactComponent as IconChannels } from "assets/images/icon_channels.svg";
import { ReactComponent as IconSettings } from "assets/images/icon_settings.svg";
import { ReactComponent as IconEquiring } from "assets/images/icon_equiring.svg";

export interface HeaderLink {
  route: string;
  name: string;
  allowedRoles: UserRoleEnum[];
  icon?: JSX.Element;
}

export const sideMenuLinks: HeaderLink[][] = [
  [
    {
      route: "/sales",
      name: "Продажі",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconSales />,
    },
  ],
  [
    {
      route: "/business-structure",
      name: "Мій Бізнес",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconMyBussiness />,
    },
    {
      route: "/reports",
      name: "Звіти",
      allowedRoles: [UserRoleEnum.Owner, UserRoleEnum.Accountant],
      icon: <IconReports />,
    },
    {
      route: "/products",
      name: "Товари",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconGoods />,
    },
  ],
  [
    {
      route: "/users",
      name: "Користувачі",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconCashier />,
    },
    {
      route: "/acquiring",
      name: "Еквайринг",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconEquiring />,
    },
    {
      route: "/channels",
      name: "Канали",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconChannels />,
    },
  ],
  [
    {
      route: "/settings",
      name: "Налаштування",
      allowedRoles: [UserRoleEnum.Owner],
      icon: <IconSettings />,
    },
  ],
];

export const headerLinks: HeaderLink[] = [
  {
    route: "/sales",
    name: "Продажі",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/reports",
    name: "Звіти",
    allowedRoles: [UserRoleEnum.Owner, UserRoleEnum.Accountant],
  },
  {
    route: "/business-structure",
    name: "Мій Бізнес",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/users",
    name: "Користувачі",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/products",
    name: "Товари",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/channels",
    name: "Канали",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/settings",
    name: "Налаштування",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/acquiring",
    name: "Еквайринг",
    allowedRoles: [UserRoleEnum.Owner],
  },
  {
    route: "/subscription",
    name: "Мій Тариф",
    allowedRoles: [UserRoleEnum.Owner],
  },
];
